import React, { useState, useEffect, useCallback } from 'react';
import api from '../lib/api';
import { nullZLSTabella } from './../lib/dati';
import Loader from './../components/Loader';
import useNotification from './../components/useNotification';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ContextCommand from '../components/ContextCommand';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormTitle from '../components/FormTitle';
import FormTextField from '../components/FormTextField';
import { DataGrid } from '@mui/x-data-grid';
import { DateTime } from "luxon";

export default function ClientiDettaglio({setTitle, setContextCommands}) {
	const { id } = useParams();
	const [ isLoaderOpen, setLoaderOpen ] = useState(false);
	const { pushNotification } = useNotification();
	const { control, handleSubmit, /*watch, trigger, getValues,*/ setValue, reset, /*formState: { errors }*/ } = useForm({mode: 'all'});
	const navigate = useNavigate();
	const [ rowsServizi, setRowsServizi ] = useState([]);

	const columnsServizi=[
		{ field: 'id', headerName: 'ID', width: 80, type:'number' },
		{ field: 'article', headerName: 'Articolo', width: 300, type:'text', flex:true },
		{ field: 'domain', headerName: 'Dominio', width: 300, type:'text' },
		{ field: 'expirationDate', headerName: 'Scadenza', width: 140, valueGetter: (params) => { return DateTime.fromSQL(params.row.expirationDate).toFormat('dd/MM/yyyy'); } },
		{ field: 'quantity', headerName: 'Quantità', width: 80, type:'number' },
		{ field: 'state', headerName: 'Stato', width: 150, valueGetter: (params) => {
			switch (params.row.state) {
				case 'Active':
					return 'Attivo';
				case 'Canceled':
					return 'Cancellato';
				case 'ToCancel':
					return 'In cancellazione';
				case 'Rejected':
					return 'Respinto';
				default:
					return params.row.state; // Se lo stato non corrisponde a nessuna delle opzioni, mantieni lo stato originale
				}
			}
		}
  	]

	const caricaCliente = () => {
		if (id!=='new') {
			api.get(`/clienti/${id}`).then(data=>{
				const cliente = data[0];
				cliente._action="e";
				api.get(`/servizi/elenco?clienteFattureInCloud=${id}`).then(data=>{
					setRowsServizi(data);
				}, error=>{
					console.log("Impossibile caricare i dati", "error");
				});
				reset(cliente);
			}, error=>{
				console.log("Impossibile caricare i dati", "error");
			});
		}
	}

	useEffect(() => {
		setLoaderOpen(true);
	    setTitle('Dettaglio del cliente');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaCliente();
  	}, [setTitle, setContextCommands]);

	useEffect(()=>{
		if (id==='new') {
			setValue('_action', 'n');
			setLoaderOpen(false);
		} else {
			api.get(`/clienti/${id}`).then(data=>{
				if (data.length!==1) {
					alert('Errore nel caricamento dei dati');
				} else {
					const cliente = data[0];
					cliente._action="e";
				}
				setLoaderOpen(false);
			});
		}
	}, [id, setValue]);

	const salva = useCallback(data=>{
		var array = [];
		array.push(data);
		const json = nullZLSTabella(array);
		api.post("/clienti", json).then(r=>{
			pushNotification("Dati salvati", "success");
			navigate("/clienti/"+r);
		}, error=>{
			pushNotification("Errore nel salvataggio del cliente", "error");
		});
	}, [pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			<ContextCommand key="1" icon="backspace" onClick={ ()=>navigate("/clienti") }>Indietro</ContextCommand>,
			/*<ContextCommand key="2" icon="floppy" onClick={ handleSubmit(data=>salva(data)) }>Salva</ContextCommand>*/
		]);
	}, [setContextCommands, handleSubmit, navigate, salva] );

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<Loader open={isLoaderOpen} />
			<Grid container spacing={2}>
				<FormTextField xs={6} md={6} size="small" control={control} name="id" label="ID" type="text" disabled/>
				<FormTextField xs={6} md={6} size="small" placeholder="Codice" control={control} name="code" label="Codice" type="text" disabled/>

				<FormTextField xs={12} md={12} size="small" placeholder="Denominazione" control={control} name="name" label="Denominazione" type="text" disabled/>
				
				<FormTextField xs={6} md={6} size="small" placeholder="Partita IVA" control={control} name="vat_number" label="Partita IVA" type="text" disabled/>
				<FormTextField xs={6} md={6} size="small" placeholder="Codice Fiscale" control={control} name="tax_code" label="Codice Fiscale" type="text" disabled/>

				<FormTextField xs={5} md={5} size="small" placeholder="Città" control={control} name="address_city" label="Città" type="text" disabled/>
				<FormTextField xs={5} md={5} size="small" placeholder="Provincia" control={control} name="address_province" label="Provincia" type="text" disabled/>
				<FormTextField xs={2} md={2} size="small" placeholder="Paese" control={control} name="country" label="Paese" type="text" disabled/>
			</Grid>
			<FormTitle variant="h6">Servizi di Aruba Business</FormTitle>
				<Grid item xs={12}>
				{rowsServizi.length === 0 ? (
					<div>Nessun servizio con rinnovo annuale presente.</div>
				) : (
					<DataGrid
						rows={rowsServizi}
						columns={columnsServizi}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 10,
								},
							},
						}}
						pageSizeOptions={[10]}
						disableRowSelectionOnClick
					/>
				)}

				</Grid>
		</Box>
	);
}
