import React, { useState } from 'react';
import Nav from './components/Nav';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './Login';
import { getCookie } from './lib/cookie';
import { BrowserRouter } from "react-router-dom";
import Routes from './Routes';
import NotificationProvider from './components/NotificationProvider';
import Notification from './components/Notification';
import './App.css';

function App() {
  if (!getCookie('auth')) return <Login />;
  return (
    <StyledApp />
  );
}

function StyledApp() {
  const [title, setTitle] = useState(false);
  const [contextCommands, setContextCommands] = useState([]);

  const Page = () => 
    <NotificationProvider>
      <Notification />
      <Routes setTitle={setTitle} setContextCommands={setContextCommands} />
    </NotificationProvider>

  return (
    <BrowserRouter>
      <div>
        <CssBaseline />
        <Nav title={title+" - Gestionale di Lanfranco Stefano"} contextCommands={contextCommands} content={Page()}/>
      </div>
    </BrowserRouter>
  );
}

export default App;
