import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import ContextCommand from '../components/ContextCommand';
import useNotification from './../components/useNotification';
import api from '../lib/api';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DateTime } from "luxon";

export default function Scadenze({setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	const [ clientiFattureInCloud, setClientiFattureInCloud ] = useState([]);
	const [ selectedIDs, setSelectedIDs ] = useState([]);
	const navigate = useNavigate();
	const { pushNotification } = useNotification();

	const columns=[
		{ field: 'id', headerName: 'ID', width: 100, type:'number' },
		{ field: 'domain', headerName: 'Dominio', width: 300 },
		{ field: 'article', headerName: 'Servizio', width: 300 },
		{ field: 'quantity', headerName: 'Quantità', width: 80, type:'number' },
		{ field: 'activationDate', headerName: 'Attivazione', width: 140, valueGetter: (params) => { return DateTime.fromSQL(params.row.activationDate).toFormat('dd/MM/yyyy'); } },
		{ field: 'expirationDate', headerName: 'Scadenza', width: 140, valueGetter: (params) => { return DateTime.fromSQL(params.row.expirationDate).toFormat('dd/MM/yyyy'); } },
		{ field: 'clienteFattureInCloud', headerName: 'Appartiene al cliente', width: 320,
		valueGetter: (params) => {
			const cliente = clientiFattureInCloud.find(cliente => cliente.id === params.row.clienteFattureInCloud);
			return cliente ? cliente.name : ''; } },
	]

	const caricaRows = () => {
		const queryParam = "state=Active&automaticRenewal=1&sort=expirationDate";
		api.get(`/servizi/elenco?${queryParam}`).then(data=>{
		  setRows(data);
		}, error=>{
		  console.log("Impossibile caricare i dati", "error");
		});
		api.get("/clienti/elenco").then(data=>{
			setClientiFattureInCloud(data);
		}, error=>{
			console.log("Impossibile caricare i dati", "error");
		});
	  }
	  

	useEffect(() => {
	    setTitle('Scadenze');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);

	const cancella = useCallback(() => {
		selectedIDs.map(function(id, row){
			api.delete("/servizi/"+id, []).then(r=>{
				pushNotification("Dati cancellati", "success");
				caricaRows();
				navigate("/servizi/");
			}, error=>{
				pushNotification("Errore nella cancellazione", "error");
			});
			return true;
		})
		setSelectedIDs([]);
	}, [selectedIDs, pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			/*<ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/servizi/new") }>Aggiungi</ContextCommand>,*/
			<ContextCommand key="2" icon="delete" onClick={ cancella }>Cancella</ContextCommand>,
		]);
	}, [setContextCommands, navigate, cancella] );

	const rowDettaglio = (params) => {
		navigate(encodeURI("/servizi/"+params.id));
	};

	const handleSelectionChange = (selection) => {
		setSelectedIDs(selection);
	};

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				In questa pagina sono presenti tutte le scadenze dei servizi attivi di Aruba Business.
			</p>
			<DataGrid
				rows={rows}
				columns={columns}
				initialState={{
					pagination: {
					paginationModel: {
						pageSize: 15,
					},
					},
				}}
				slots={{
					toolbar: GridToolbar,
				}}
				pageSizeOptions={[15]}
				checkboxSelection
				onRowSelectionModelChange={handleSelectionChange}
				disableRowSelectionOnClick
				onRowClick={rowDettaglio}
			/>
		</Box>
		);
}
