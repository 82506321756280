export function nullZLS(s) {
  if (!s)
    return null;
  return s;
}

export function nullZLSTabella(t_orig) {
  var t=JSON.parse(JSON.stringify(t_orig));
  for (var i=0; i<t.length; i++) {
    for (var key in t[i]) {
      if (t[i][key] === "") {
        t[i][key] = null;
      }
    }
  }
  return t;
}


export function rimuoviNull(t) {
  if (t===null) return '';  //se è un valore singolo ritorna ''

  for (var i=0; i<t.length; i++) {
    for (var key in t[i]) {
      if (t[i][key] === null) {
        t[i][key] = "";
      }
    }
  }
  return t;
}

export function formatFloat(f)	{
	if (isNaN(parseFloat(f)))
		return null;
	return parseFloat(f).toString().replace('.',',');
}

export function bool(v) {
  if (typeof(v) === "undefined" ) return false;
  if (v === null ) return false;

  if (typeof v === "string") {
    v=v.toLowerCase();
    switch(v) {
      case "1":
      case "si":
      case "sì":
      case "s":
      case "true":
      case "-1":
        return true;
      default:
        return false;
    }
  }
  return (!!v);
}

export function removeTags(str) {
	if ((str===null) || (str===''))
		return false;
	else
		str = str.toString();
		
	// Regular expression to identify HTML tags in
	// the input string. Replacing the identified
	// HTML tag with a null string.
	return str.replace( /(<([^>]+)>)/ig, '');
}