const username = localStorage.getItem('username');

var menu = [];

menu.push({ id: 0, text: "Home", icon: "home", url: "/", home: true });
menu.push({ id: 1, text: "-" });
menu.push({ id: 2, text: "Clienti", icon: "account-multiple", url: "/clienti", home: false });
menu.push({ id: 3, text: "-" });
menu.push({ id: 4, text: "Contratti", icon: "file-document-edit", url: "/contratti", home: false });
menu.push({ id: 5, text: "Servizi", icon: "account-wrench", url: "/servizi", home: false });
menu.push({ id: 6, text: "Scadenze", icon: "account-wrench", url: "/scadenze", home: false });
menu.push({ id: 7, text: "Listino", icon: "list-box", url: "/listino", home: false });

if (username === "stefano.lanfranco") {
  menu.push({ id: 10, text: "-" });
  menu.push({ id: 11, text: "Sync", icon: "cogs", subMenu: [
    { id: 200, text: "Fatture in Cloud", icon: "receipt-text", url: "/sync/fattureincloud" },
    { id: 201, text: "Aruba Business", icon: "web-sync", url: "/sync/arubabusiness" },
    { id: 202, text: "Pianeta Fibra", icon: "ip", url: "/sync/pianetafibra" },
  ]});
}

menu.push({ id: 16, text: "-" });
menu.push({ id: 17, text: "Logout", icon: "logout", url: "/logout", home: false });

export default menu;