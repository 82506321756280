import React, { useEffect } from 'react';
import Box from '@mui/material/Box';

export default function Home({setTitle, setContextCommands}) {

	useEffect(() => {
	    setTitle('Home');
		const contextCommands=[];
		setContextCommands(contextCommands);
  }, [setTitle, setContextCommands]);

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<h1>Lanfranco Stefano</h1>
			<p>Questo software di gestione permette di controllare tutti i clienti presenti, i servizi relativi e le loro scadenze.</p>
		</Box>
		);
}
