import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import ContextCommand from '../components/ContextCommand';
import useNotification from './../components/useNotification';
import api from '../lib/api';
import { nullZLSTabella } from './../lib/dati';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DateTime } from "luxon";
import Dialog from '@mui/material/Dialog';
import FormSelect from '../components/FormSelect';
import Button from '@mui/material/Button';

export default function Servizi({setTitle, setContextCommands}) {
	const [ rows, setRows ] = useState([]);
	const [ selectedIDs, setSelectedIDs ] = useState([]);
	const [ openDialog, setOpenDialog ] = useState(false);
	const { control, handleSubmit, /*watch, trigger, getValues, setValue,*/ reset, /*formState: { errors }*/ } = useForm({mode: 'all'});
	const navigate = useNavigate();
	const { pushNotification } = useNotification();
	const [ clientiFattureInCloud, setClientiFattureInCloud ] = useState([]);

	const columns=[
		{ field: 'id', headerName: 'ID', width: 100, type:'number' },
		{ field: 'domain', headerName: 'Dominio', width: 300 },
		{ field: 'article', headerName: 'Servizio', width: 300 },
		{ field: 'quantity', headerName: 'Quantità', width: 80, type:'number' },
		{ field: 'activationDate', headerName: 'Attivazione', width: 140, valueGetter: (params) => { return DateTime.fromSQL(params.row.activationDate).toFormat('dd/MM/yyyy'); } },
		{ field: 'expirationDate', headerName: 'Scadenza', width: 140, valueGetter: (params) => { return DateTime.fromSQL(params.row.expirationDate).toFormat('dd/MM/yyyy'); } },
		{ field: 'clienteFattureInCloud', headerName: 'Appartiene al cliente', width: 320,
		valueGetter: (params) => {
			const cliente = clientiFattureInCloud.find(cliente => cliente.id === params.row.clienteFattureInCloud);
			return cliente ? cliente.name : ''; } },
		{ field: 'state', headerName: 'Stato', width: 150, valueGetter: (params) => {
			switch (params.row.state) {
				case 'Active':
					return 'Attivo';
				case 'Canceled':
					return 'Cancellato';
				case 'ToCancel':
					return 'In cancellazione';
				case 'Rejected':
					return 'Respinto';
				default:
					return params.row.state; // Se lo stato non corrisponde a nessuna delle opzioni, mantieni lo stato originale
				}
			}
		}
	]

	const caricaRows = () => {
		api.get("/servizi/elenco").then(data=>{
		  setRows(data);
		}, error=>{
		  console.log("Impossibile caricare i dati", "error");
		});
		api.get("/clienti/elenco?sort=name").then(data=>{
			setClientiFattureInCloud(data);
		}, error=>{
			console.log("Impossibile caricare i dati", "error");
		})
	}

	useEffect(() => {
	    setTitle('Servizi');
		const contextCommands=[];
		setContextCommands(contextCommands);
		caricaRows();
	}, [setTitle, setContextCommands]);

	const apriDialog = useCallback(() => {
        setOpenDialog(true);
    }, []);

    const chiudiDialog = useCallback(() => {
		reset();
        setOpenDialog(false);
    }, []);

	const aggiornaElementiSelezionati = useCallback(() => {
        if (selectedIDs.length > 0) {
            apriDialog();
        }
    }, [selectedIDs, apriDialog]);

	const assegnaCliente = useCallback(data=>{
        selectedIDs.forEach(id => {
			var array = [];
			array.push(data);
			const json = nullZLSTabella(array);
			json[0].id = id;
			json[0]._action = "e";
			api.post("/servizi", json).then(r=>{
				pushNotification("Cliente assegnato con successo al servizio", "success");
				caricaRows();
			}, error=>{
				pushNotification("Errore nell'assegnazione del cliente", "error");
			});
        });
        chiudiDialog();
        setSelectedIDs([]);
    }, [selectedIDs, pushNotification, caricaRows, chiudiDialog]);


	const cancella = useCallback(() => {
		selectedIDs.map(function(id, row){
			api.delete("/servizi/"+id, []).then(r=>{
				pushNotification("Dati cancellati", "success");
				caricaRows();
				navigate("/servizi/");
			}, error=>{
				pushNotification("Errore nella cancellazione", "error");
			});
			return true;
		})
		setSelectedIDs([]);
	}, [selectedIDs, pushNotification, navigate]);

	useEffect(() => {
		setContextCommands([
			/*<ContextCommand key="1" icon="plus" onClick={ ()=>navigate("/servizi/new") }>Aggiungi</ContextCommand>,*/
			<ContextCommand key="2" icon="account-edit" onClick={ aggiornaElementiSelezionati }>Assegna Cliente</ContextCommand>,
			<ContextCommand key="3" icon="delete" onClick={ cancella }>Cancella</ContextCommand>,
		]);
	}, [setContextCommands, navigate, aggiornaElementiSelezionati, cancella] );

	const rowDettaglio = (params) => {
		navigate(encodeURI("/servizi/"+params.id));
	};

	const handleSelectionChange = (selection) => {
		setSelectedIDs(selection);
	};

	return (
		<Box
			sx={{ height: '100%', width: '100%' }}
			noValidate
			autoComplete="off"
		>
			<p>
				In questa pagina sono presenti tutti i servizi di Aruba Business.
			</p>
			<DataGrid
			rows={rows}
			columns={columns}
			initialState={{
				pagination: {
				paginationModel: {
					pageSize: 15,
				},
				},
			}}
			slots={{
				toolbar: GridToolbar,
			}}
			pageSizeOptions={[15]}
			checkboxSelection
			onRowSelectionModelChange={handleSelectionChange}
			disableRowSelectionOnClick
			onRowClick={rowDettaglio}
			/>

			<Dialog
				open={openDialog}
				onClose={chiudiDialog}
				fullWidth
				maxWidth="md"
				PaperProps={{
					style: {
						width: '50vw',
						height: '50vh',
						maxHeight: 'none', // Per garantire che l'altezza sia al massimo 50% del viewport
						margin: 'auto', // Posiziona la dialog al centro
						display: 'flex', // Usa flexbox per allineare verticalmente il contenuto
						flexDirection: 'column', // Imposta il layout del contenuto in una colonna
						justifyContent: 'center', // Allinea il contenuto verticalmente al centro
						padding: '16px', // Aggiungi spazio intorno al contenuto
					},
				}}
			>
				<div style={{ flex: 1 }}>
					Questi procedura serve per assegnare i servizi selezionati, al cliente scelto.
				</div>
				<div style={{ flex: 1 }}>
					<Grid container spacing={2}>
						<Grid item xs={12} textAlign="center">
							<FormSelect xs={12} md={12} control={control} name='clienteFattureInCloud' label="Assegnati al cliente" options={clientiFattureInCloud} valueField='id' labelField='name' />
						</Grid>
						<Grid item xs={12} textAlign="center">
							<Button variant="contained" sx={{ width: '100%' }} onClick={ handleSubmit(data=>assegnaCliente(data)) }>Assegna</Button>
						</Grid>
					</Grid>
				</div>
				<div style={{ flex: 1 }}>
				</div>
			</Dialog>
		</Box>
		);
}
