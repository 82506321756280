import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function Loader({open})  {

  return (
    <React.Fragment>
      <Backdrop open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  )
}
