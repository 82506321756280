import React, { useEffect } from 'react';
import { deleteCookie } from './lib/cookie';

export default function Logout() {

  const handleLogout = () => {
    if (!window.confirm('Confermi la disconnessione')) return;
    deleteCookie("auth");
    window.location.assign("/");
  }

  useEffect(() => {
    handleLogout();
  }, [])

  return (
    <div>

    </div>
  );
}
