import React from 'react';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import DrawerContent from './DrawerContent';
import '@mdi/font/css/materialdesignicons.min.css';
import menu from './menu';

const drawerWidth = 240;

export default function MainMenu(props) {

  return (
    <nav>
      <Hidden smUp implementation="css">
        <Drawer open={props.mobileOpen} onClose={props.handleDrawerToggle} ModalProps={{ keepMounted: true }}>
          <DrawerContent menu={menu} />
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer open variant="permanent" sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}>
          <DrawerContent menu={menu} />
        </Drawer>
      </Hidden>
    </nav>
  );
}
