import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import Loader from './../../components/Loader';
import useNotification from './../../components/useNotification';
import api from '../../lib/api';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import logo from './logo.png';

export default function FattureInCloud({ setTitle, setContextCommands }) {
	const navigate = useNavigate();
    const [isLoaderOpen, setLoaderOpen] = useState(false);
	const { pushNotification } = useNotification();

    useEffect(() => {
        setTitle('SYNC Fatture in Cloud');
        const contextCommands = [];
        setContextCommands(contextCommands);
    }, [setTitle, setContextCommands]);

	const handleFattureInCloudClienti = useCallback(() => {
        setLoaderOpen(true);
		api.get("/fattureincloud/token/1/clienti", []).then(r=>{
			setLoaderOpen(false);
            pushNotification("Elenco clienti sincronizzzato", "success");
			navigate("/clienti/");
		}, error=>{
			setLoaderOpen(false);
            pushNotification("Errore nella sincronizzazione", "error");
		});
		return true;
	}, [pushNotification, navigate]);

	const handleFattureInCloudProdotti = useCallback(() => {
        setLoaderOpen(true);
		api.get("/fattureincloud/token/1/prodotti", []).then(r=>{
			setLoaderOpen(false);
            pushNotification("Elenco prodotti sincronizzzato", "success");
			navigate("/listino/");
		}, error=>{
			setLoaderOpen(false);
            pushNotification("Errore nella sincronizzazione", "error");
		});
		return true;
	}, [pushNotification, navigate]);

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Loader open={isLoaderOpen}/>
            <img 
                src={logo} 
                alt="Fatture in cloud" 
                style={{
                    width: '25%', // Ridimensiona l'immagine al 25% della larghezza del monitor
                    display: 'block', // Permette di centrare l'immagine
                    margin: '0 auto' // Centra l'immagine orizzontalmente
                }} 
            />
            <p>
                Questa pagina, serve a sincronizzare i dati presenti su Fatture in Cloud.
            </p>
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign="center">
                    <Button variant="contained" sx={{ width: '100%' }} onClick={handleFattureInCloudClienti}>SYNC di tutti i clienti</Button>
                </Grid>
                <Grid item xs={12} textAlign="center">
                    <Button variant="contained" sx={{ width: '100%' }} onClick={handleFattureInCloudProdotti}>SYNC di tutti i prodotti</Button>
                </Grid>
            </Grid>
        </Box>
    );
}
