import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import Loader from './../../components/Loader';
import useNotification from './../../components/useNotification';
import api from '../../lib/api';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import logo from './logo.png';

export default function ArubaBusiness({ setTitle, setContextCommands }) {
    const navigate = useNavigate();
    const [isLoaderOpen, setLoaderOpen] = useState(false);
    const { pushNotification } = useNotification();
    const [opt, setOpt] = useState("");
    const [authValid, setAuthValid] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const formatDate = (dateString) => {
        const options = {
            day: '2-digit', 
            month: '2-digit', 
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        return new Date(dateString).toLocaleString(undefined, options);
    };

    const loadData = useCallback(() => {
        api.get("/arubabusiness/expires/1", []).then(r => {
            const currentDate = new Date();
            const dateExpires = new Date(r[0].expires);
            setAuthValid(r[0].expires);
            setIsButtonDisabled(!(dateExpires > currentDate || r[0].expires));
        }, error => {
            pushNotification("Errore", "error");
        });
    }, [pushNotification]);

    useEffect(() => {
        setTitle('SYNC Aruba Business');
        const contextCommands = [];
        setContextCommands(contextCommands);
        loadData();
    }, [setTitle, setContextCommands, loadData]);

    const handleOptChange = (event) => {
        setOpt(event.target.value);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleArubaBusinessLogin();
        }
    };

    const handleArubaBusinessLogin = useCallback(() => {
        if (!opt.trim()) {
            pushNotification("Il Codice OPT non può essere vuoto", "error");
            return false;
        }
        setLoaderOpen(true);
        api.post("/arubabusiness/token/1/"+opt, []).then(r=>{
            setLoaderOpen(false);
            pushNotification("Auteniticazione eseguita correttamente", "success");
            setOpt('');
            loadData();
        }, error=>{
            setLoaderOpen(false);
            pushNotification("Errore nell'autenicazione", "error");
        });
        return true;
    }, [opt, pushNotification]);

    const handleArubaBusinessServizi = useCallback(() => {
        setLoaderOpen(true);
        api.get("/arubabusiness/servizi/1", []).then(r=>{
            setLoaderOpen(false);
            pushNotification("Elenco servizi sincronizzzato", "success");
            navigate("/servizi/");
        }, error=>{
            setLoaderOpen(false);
            pushNotification("Errore nella sincronizzazione dei servizi", "error");
        });
        return true;
    }, [opt, pushNotification, navigate]);

    const handleResetArubaBusinessLogin = useCallback(() => {
        setLoaderOpen(true);
        api.delete("/arubabusiness/token/1/reset", []).then(r=>{
            setLoaderOpen(false);
            pushNotification("RESET effettuato correttamente", "success");
            loadData();
        }, error=>{
            setLoaderOpen(false);
            pushNotification("Errore", "error");
        });
        return true;
    }, [pushNotification]);

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Loader open={isLoaderOpen}/>
            <img 
                src={logo} 
                alt="Aruba Business" 
                style={{
                    width: '25%', // Ridimensiona l'immagine al 25% della larghezza del monitor
                    display: 'block', // Permette di centrare l'immagine
                    margin: '0 auto' // Centra l'immagine orizzontalmente
                }} 
            />
            <p>
                Questa pagina, serve a sincronizzare i dati presenti su Aruba Business (mettendo il codice OPT).
            </p>
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign="center">
                {isButtonDisabled && (
                    <TextField
                        label="Codice OPT"
                        variant="outlined"
                        value={opt}
                        onChange={handleOptChange}
                        onKeyDown={handleKeyDown}
                        sx={{ width: '100%' }}
                    />
                )}
                </Grid>
                <Grid item xs={11} textAlign="center">
                    <Button
                        variant="contained"
                        sx={{ width: '100%' }}
                        onClick={handleArubaBusinessLogin}
                        disabled={!isButtonDisabled}
                    >
                        {isButtonDisabled ? 'Autenticazione' : `Autenticazione valida fino al ${formatDate(authValid)}`}
                    </Button>
                </Grid>
                <Grid item xs={1} textAlign="center">
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ width: '100%' }}
                        onClick={handleResetArubaBusinessLogin}
                        disabled={isButtonDisabled}
                    >
                        RESET
                    </Button>
                </Grid>
                <Grid item xs={12} textAlign="center">
                    {!isButtonDisabled && (
                        <Button
                            variant="contained"
                            sx={{ width: '100%' }}
                            onClick={handleArubaBusinessServizi}
                        >
                            SYNC di tutti i servizi
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
